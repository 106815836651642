import React, { useState } from "react";
import {
  Box,
  Flex,
  SlideFade,
  Text,
  Icon,
  useColorModeValue,
  Center,
  Link,
} from "@chakra-ui/react";
import Login from "../components/auth/Login";
import styled from "styled-components";
import Card from "../components/general/Card";
import Signup from "../components/auth/SignUp";
import ResetPassword from "../components/auth/ResetPassword";
import Verification from "../components/auth/Verification";
import SetPassword from "../components/auth/SetPassword";
import DisclosureModal from "../components/auth/DisclosureModal";
import MFA from "../components/auth/MFA";
import { CheckIcon, WarningIcon } from "@chakra-ui/icons";
import { AlpacaDarkSVG, AlpacaIconFilled, AlpacaSVG } from "../icons/Login";
import useWindowDimensions from "../globals/windowdim";
import { TradingAPISrc } from "../globals/consts";
import colors from "../components/theme/colors";
import { getAmplitude, Tokens, EventType } from "../globals/amplitude";

interface AuthProps {
  type:
    | "login"
    | "signup"
    | "forgot-password"
    | "verification"
    | "set-password"
    | "mfa";
}

const RightSideBox = styled(Box)`
  margin: -1rem;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  flex: 2;
  position: relative;
`;

const PicBox = styled(Box)`
  position: absolute;
  bottom: 0px;
`;

const LinkDisclosure = styled(Link)`
  background-color: transparent;
  cursor: pointer;
  text-decoration: underline;
  color: ${colors.yellow};
  font-weight: "bold";
`;

const SignUpInfo = () => {
  const svg = useColorModeValue(AlpacaSVG, AlpacaDarkSVG);
  const linkColor = useColorModeValue(colors.yellow, colors.yellow500);

  const CheckMark = () => (
    <CheckIcon color="success" m="2px" mr="6px" fontSize="lg" />
  );
  const winSize = useWindowDimensions();

  let alpacaSize = "350px";
  if (winSize === "small") alpacaSize = "0px";

  return (
    <RightSideBox>
      <Box p="3rem">
        <Text fontSize="19px" ml="5px" fontWeight="bold" mt="3rem" mb="0.5rem">
          Welcome to Alpaca Broker API!
        </Text>
        <Flex mt="2rem">
          <CheckMark />
          <Text zIndex="1" fontSize="14px">
            Sign up with your name and email and start exploring Alpaca&apos;s
            Broker APIs.
          </Text>
        </Flex>
        <Flex mt="1rem">
          <CheckMark />
          <Text zIndex="1" fontSize="14px">
            Want to build a trading app? Integrate trading into a banking app,
            or a payroll app? We got you covered. Our APIs are super flexible
            and scalable.
          </Text>
        </Flex>
        <Flex mt="1rem">
          <CheckMark />
          <Text zIndex="1" fontSize="14px">
            Ready to go live? Migrate your app to production whenever
            you&apos;re ready.
          </Text>
        </Flex>
        <Box w="90%" position="absolute" bottom="10" zIndex={10}>
          <Center>
            <Box maxW="70%" textAlign="center">
              <Box fontSize="14px" display="inline">
                For algorithmic trading, quants funds, and trading your own
                funds with Trading API, visit
                <a
                  href={TradingAPISrc}
                  onClick={() =>
                    getAmplitude(Tokens.BROKERDASH, Tokens.ALGODASH).map(
                      (instance) =>
                        instance.track({
                          event_type: EventType.ALGODASH_LOGIN_CLICKED,
                          event_properties: {
                            url: "https://broker-app.alpaca.markets/sign-up",
                          },
                        })
                    )
                  }
                >
                  <Text
                    display="inline"
                    fontWeight={500}
                    fontSize="14px"
                    color={linkColor}
                    ml="5px"
                  >
                    Trading API.
                  </Text>
                </a>
              </Box>
            </Box>
          </Center>
        </Box>
      </Box>
      <PicBox transform="scaleX(-1)" right="0">
        <Icon as={svg} fontSize={alpacaSize} />
      </PicBox>
      <PicBox right="40%">
        <Icon as={svg} fontSize="225px" />
      </PicBox>
    </RightSideBox>
  );
};

const AuthPage = (props: AuthProps): React.ReactElement => {
  const window = useWindowDimensions();

  let showSignupInfo = true;
  let minWidth = "750px";
  let padding = "7rem";
  let footerPadding = "8rem";

  if (props.type === "signup") {
    padding = "4rem";
    footerPadding = "5rem";
  }

  if (window === "mobile") {
    padding = "1rem";
    footerPadding = "2rem";
    minWidth = "90vw";
    showSignupInfo = false;
  }

  if (window === "small") {
    padding = "3rem";
    footerPadding = "4rem";
    showSignupInfo = false;
  }

  const [isDisclosureModalOpen, setModalState] = useState(false);
  const currentYear = new Date().getFullYear();

  const renderSwitch = () => {
    switch (props.type) {
      case "login":
        return <Login p={padding} flex="3" />;
      case "signup":
        return (
          <Flex>
            <Signup p={padding} flex="2.5" />
            {showSignupInfo && <SignUpInfo />}
          </Flex>
        );
      case "forgot-password":
        return <ResetPassword p={padding} />;
      case "verification":
        return <Verification p={padding} />;
      case "set-password":
        return <SetPassword p={padding} />;
      case "mfa":
        return <MFA p={padding} />;
    }
  };

  const Disclaimer = () => (
    <Box fontSize="10px" letterSpacing="1px">
      <Flex>
        <AlpacaIconFilled width="25px" height="25px" />
        <Text mt="0.5" ml="0.5rem" fontSize="15px" fontWeight={500}>
          Alpaca
        </Text>
      </Flex>

      <Text mt="2rem">
        © {currentYear} Alpaca Securities LLC All rights reserved.
      </Text>
      <Text>© {currentYear} Alpaca Crypto LLC All rights reserved.</Text>
      <Text>© {currentYear} AlpacaDB, Inc. All rights reserved.</Text>

      <Text mt="1rem">
        Securities Brokerage services are provided by Alpaca Securities LLC
        (&quot;Alpaca Securities&quot;), member&nbsp;
        <LinkDisclosure href="https://www.finra.org/">FINRA</LinkDisclosure>/
        <LinkDisclosure href="https://www.sipc.org/">SIPC</LinkDisclosure>, a
        wholly-owned subsidiary of AlpacaDB, Inc. Technology and services are
        offered by AlpacaDB, Inc.
      </Text>
      <Text mt="1rem">
        Cryptocurrency services are made available by Alpaca Crypto LLC
        (&quot;Alpaca Crypto&quot;), a FinCEN registered money services business
        (NMLS # 2160858), and a wholly-owned subsidiary of AlpacaDB, Inc. Alpaca
        Crypto is not a member of SIPC or FINRA. Cryptocurrencies are not stocks
        and your cryptocurrency investments are not protected by either FDIC or
        SIPC. Please see the&nbsp;
        <LinkDisclosure href="https://alpaca.markets/disclosures">
          Disclosure Library
        </LinkDisclosure>
        &nbsp; for more information.
      </Text>
      <Text mt="1rem">
        This is not an offer, solicitation of an offer, or advice to buy or sell
        securities or cryptocurrencies, or open a brokerage account or
        cryptocurrency account in any jurisdiction where Alpaca Securities or
        Alpaca Crypto respectively, are not registered or licensed, as
        applicable.
      </Text>
      <LinkDisclosure onClick={() => setModalState(true)} mt="1rem">
        <WarningIcon color={colors.yellow} pb="1px" />
        &nbsp;View Important Disclosures
      </LinkDisclosure>
      {isDisclosureModalOpen && (
        <DisclosureModal
          isOpen={isDisclosureModalOpen}
          closeModal={() => {
            setModalState(false);
          }}
          minWidth={minWidth}
        />
      )}
    </Box>
  );

  return (
    <>
      <Box pl={padding} pr={padding}>
        <Center m="52px 0px">
          <AlpacaIconFilled width="54px" height="54px" />
          <Text ml="10px" fontSize="34px" fontWeight={500}>
            Alpaca
          </Text>
        </Center>
        <SlideFade offsetY="40px" in>
          <Card boxShadow="xl" minWidth={minWidth}>
            {renderSwitch()}
          </Card>
        </SlideFade>
      </Box>
      <Box color="gray" mt="4rem" minWidth={minWidth} px={footerPadding}>
        <Disclaimer />
      </Box>
    </>
  );
};

export default AuthPage;
