import React from "react";

import { Session } from "../session";
import { Link as ReactRouterLink } from "react-router-dom";
import {
  Box,
  Button,
  HStack,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { EventType, getAmplitude } from "../../../globals/amplitude";
import colors from "../../../components/theme/colors";

const Completed = ({ reset }: Session): React.ReactElement => (
  <Box maxW="1000px">
    <Text fontSize="xl" fontWeight="medium">
      Congratulations!
    </Text>
    <Text mt=".5rem">
      You&apos;ve completed our Quick Start Guide! Now you know how to create an
      account, create an ACH relationship, fund an account and place an order!
      Learn more about what you can do with the Alpaca Broker API{" "}
      <Link>here</Link>.
    </Text>
    <HStack mt="2rem" spacing="2rem">
      <Button as={ReactRouterLink} to="/dashboard">
        Go to Dashboard
      </Button>
      <Button
        variant="unstyled"
        onClick={() => {
          reset();
          getAmplitude().track({
            event_type: EventType.QUICKSTART_RESTART,
          });
        }}
      >
        Restart
      </Button>
    </HStack>
    <Text mt="2rem">
      Feel lost? <br />
      Learn more about{" "}
      <Link
        color={useColorModeValue(colors.yellow, colors.yellow500)}
        fontWeight="500"
        textDecor="underline"
        href="https://alpaca.markets/docs/broker/"
        target="_blank"
      >
        Broker API here
      </Link>
    </Text>
  </Box>
);

export default Completed;
