import React from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Menu, MenuButton, Select, MenuList, MenuItem } from "@chakra-ui/react";
import { capitalize } from "../../globals/utils";
import FilterHeader from "./FormHeader";

interface FilterSelectProps {
  header: string;
  options: string[];
  onSelect: (value: string) => void;
  selected: string;
  noOptionFmt?: boolean;
  disabled?: boolean;
}

const FilterSelect = (props: FilterSelectProps): React.ReactElement => {
  const fmt = (value: string) => {
    if (props.noOptionFmt) return value;
    return capitalize(value);
  };

  return (
    <>
      <FilterHeader value={props.header} />
      <Menu>
        <MenuButton width="100%" disabled={!!props.disabled}>
          <Select
            variant="filled"
            placeholder={fmt(props.selected)}
            disabled={!!!!props.disabled}
          />
        </MenuButton>
        <MenuList>
          {props.options.map((option) => (
            <MenuItem key={option} onClick={() => props.onSelect(option)}>
              {fmt(option)}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  );
};

export default FilterSelect;
