import React from "react";
import styled from "@emotion/styled";

import { Alert, AlertIcon, Box, useToast } from "@chakra-ui/react";
import { useQuery } from "react-query";

import TicketTable from "./table";
import Header from "../../components/layout/Header";
import { getCorrespondent } from "../../api/api";

const Wrapper = styled(Box)`
  table > tbody > tr > td:nth-of-type(5) {
    max-width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const SupportTickets = (): JSX.Element => {
  const toast = useToast();
  const { data, isLoading } = useQuery(
    "correspondent",
    () => getCorrespondent(),
    {
      onError: (err: Error) => {
        toast({
          title: "An error occurred fetching correspondent",
          description: err.message,
          status: "error",
        });
      },
    }
  );

  return (
    <Wrapper>
      <Header title="Support > Tickets" />
      {!isLoading && !data?.zendesk_organization_id && (
        <Alert status="warning" maxW="750px" mb="0.5rem">
          <AlertIcon /> There is no associated Zendesk Organization ID with this
          account.
        </Alert>
      )}
      <TicketTable enabled={!!data?.zendesk_organization_id} />
    </Wrapper>
  );
};

export default SupportTickets;
