import React from "react";

import {
  Center,
  Flex,
  Link,
  Tooltip,
  useClipboard,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";
import colors from "../theme/colors";

type UUIDProps = {
  onClick?: () => void;
  value: string;
};

const UUID = ({ onClick, value }: UUIDProps): JSX.Element => {
  const { onCopy } = useClipboard(value);
  const toast = useToast();

  const copyToClipboard = () => {
    onCopy();
    toast({
      description: "Copied to clipboard",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  const linkColor = useColorModeValue(colors.yellow, colors.yellow500);

  return (
    <Flex>
      <Center color={linkColor}>
        <Link onClick={onClick} color={linkColor} mr=".25rem" width="308px">
          {value}
        </Link>
        <Tooltip label="Copy to clipboard">
          <CopyIcon cursor="pointer" onClick={copyToClipboard} />
        </Tooltip>
      </Center>
    </Flex>
  );
};

export default UUID;
