import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const Report = createIcon({
  displayName: "Report",
  viewBox: "0 0 16 20",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 18H14V6.82843L11.5858 4.41421L9.17157 2H2V18ZM2 0C0.895431 0 0 0.895431 0 2V18C0 19.1046 0.895431 20 2 20H14C15.1046 20 16 19.1046 16 18V6.82843C16 6.29799 15.7893 5.78929 15.4142 5.41421L13 3L10.5858 0.585786C10.2107 0.210714 9.70201 0 9.17157 0H2ZM4 11C4 10.4477 4.44772 10 5 10H9C9.55228 10 10 10.4477 10 11C10 11.5523 9.55228 12 9 12H5C4.44772 12 4 11.5523 4 11ZM5 14C4.44772 14 4 14.4477 4 15C4 15.5523 4.44772 16 5 16H11C11.5523 16 12 15.5523 12 15C12 14.4477 11.5523 14 11 14H5Z"
    />
  ),
});

export const Transaction = createIcon({
  displayName: "Transaction",
  viewBox: "0 0 24 24",
  path: [
    <path
      key="1"
      d="M3 12C3 7.02944 7.02944 3 12 3C14.5061 3 16.773 4.02431 18.4048 5.6771"
      strokeWidth="2"
      strokeLinecap="round"
      fill="none"
    />,
    <path
      key="2"
      d="M21 12C21 16.9706 16.9706 21 12 21C9.52317 21 7.28003 19.9995 5.65268 18.3806"
      strokeWidth="2"
      strokeLinecap="round"
      fill="none"
    />,
    <path
      key="3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 6V7.52138C13.48 7.64105 13.9231 7.84671 14.2971 8.12721C14.9208 8.59501 15.4 9.31762 15.4 10.2H13.4C13.4 10.0883 13.342 9.91087 13.0971 9.72721C12.8519 9.54331 12.4673 9.4 12 9.4C11.5327 9.4 11.1481 9.54331 10.9029 9.72721C10.6581 9.91087 10.6 10.0883 10.6 10.2C10.6 10.3117 10.6581 10.4891 10.9029 10.6728C11.1481 10.8567 11.5327 11 12 11C12.8582 11 13.6736 11.2596 14.2971 11.7272C14.9208 12.195 15.4 12.9176 15.4 13.8C15.4 14.6824 14.9208 15.405 14.2971 15.8728C13.9231 16.1533 13.48 16.359 13 16.4786V18H11V16.4786C10.52 16.359 10.0769 16.1533 9.70295 15.8728C9.07921 15.405 8.60001 14.6824 8.60001 13.8H10.6C10.6 13.9117 10.6581 14.0891 10.9029 14.2728C11.1481 14.4567 11.5327 14.6 12 14.6C12.4673 14.6 12.8519 14.4567 13.0971 14.2728C13.342 14.0891 13.4 13.9117 13.4 13.8C13.4 13.6883 13.342 13.5109 13.0971 13.3272C12.8519 13.1433 12.4673 13 12 13C11.1418 13 10.3264 12.7404 9.70295 12.2728C9.07921 11.805 8.60001 11.0824 8.60001 10.2C8.60001 9.31762 9.07921 8.59501 9.70295 8.12721C10.0769 7.84671 10.52 7.64105 11 7.52138V6H13Z"
    />,
    <path key="4" d="M3 16L0 12H6L3 16Z" />,
    <path key="5" d="M21 9L24 13L18 13L21 9Z" />,
  ],
});

export const Api = createIcon({
  displayName: "Api",
  viewBox: "0 0 24 24",
  path: [
    <path
      key="1"
      d="M7.5 7.5L3.92894 11.0711L7.5 14.6421"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />,
    <path
      key="2"
      d="M16.5 16.6422L20.0711 13.0711L16.5 9.50003"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />,
    <path
      key="3"
      d="M14.0706 4.20081L9.92945 19.6556"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});

export const Users = createIcon({
  displayName: "Users",
  viewBox: "0 0 24 24",
  path: [
    <circle cx="8" cy="10" r="4" strokeWidth="2" key="1" fill="none" />,
    <path
      d="M14 19C13.0401 16.0682 10.7154 14 8 14C5.28458 14 2.95985 16.0682 2 19"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      key="2"
      fill="none"
    />,
    <circle
      cx="17.5"
      cy="11.5"
      r="2.5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      key="3"
    />,
    <path
      d="M22 17.5C21.2801 15.7409 19.5366 14.5 17.5 14.5C16.5855 14.5 15.7302 14.7502 15 15.1851"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      key="4"
      fill="none"
    />,
  ],
});

export const Wallet = createIcon({
  displayName: "Wallet",
  viewBox: "0 0 24 24",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 6C3 4.34315 4.34315 3 6 3H15C16.6569 3 18 4.34315 18 6V7H19C20.1046 7 21 7.89543 21 9V11V12V16V17V19C21 20.1046 20.1046 21 19 21H6C4.34315 21 3 19.6569 3 18V6.5H3.04148C3.0142 6.3374 3 6.17036 3 6ZM19 15V13H14C13.4477 13 13 13.4477 13 14C13 14.5523 13.4477 15 14 15H19ZM19 11H14C12.3431 11 11 12.3431 11 14C11 15.6569 12.3431 17 14 17H19V19H6C5.44772 19 5 18.5523 5 18V8.82929C5.31278 8.93985 5.64936 9 6 9H12H17H18H19V11ZM15 5C15.5523 5 16 5.44772 16 6V7H12H6C5.44772 7 5 6.55228 5 6C5 5.44772 5.44772 5 6 5H15Z"
    />
  ),
});

export const Shapes = createIcon({
  displayName: "Shapes",
  viewBox: "0 0 24 24",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 5H9V9H5V5ZM3 5C3 3.89543 3.89543 3 5 3H9C10.1046 3 11 3.89543 11 5V9C11 10.1046 10.1046 11 9 11H5C3.89543 11 3 10.1046 3 9V5ZM5 15H9V19H5V15ZM3 15C3 13.8954 3.89543 13 5 13H9C10.1046 13 11 13.8954 11 15V19C11 20.1046 10.1046 21 9 21H5C3.89543 21 3 20.1046 3 19V15ZM19 15H15V19H19V15ZM15 13C13.8954 13 13 13.8954 13 15V19C13 20.1046 13.8954 21 15 21H19C20.1046 21 21 20.1046 21 19V15C21 13.8954 20.1046 13 19 13H15ZM15.5858 8.41422C14.8047 7.63317 14.8047 6.36684 15.5858 5.58579C16.3668 4.80474 17.6332 4.80474 18.4142 5.58579C19.1953 6.36684 19.1953 7.63317 18.4142 8.41422C17.6332 9.19526 16.3668 9.19526 15.5858 8.41422ZM14.1716 4.17157C15.7337 2.60948 18.2663 2.60948 19.8284 4.17157C21.3905 5.73367 21.3905 8.26633 19.8284 9.82843C18.2663 11.3905 15.7337 11.3905 14.1716 9.82843C12.6095 8.26633 12.6095 5.73367 14.1716 4.17157Z"
    />
  ),
});

export const CountryRiskIcon = createIcon({
  displayName: "Country Risk",
  viewBox: "0 0 26 26",
  path: [
    <path
      key="1"
      d="M8.50857 14.4569L11.4999 20"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />,
    <path
      key="2"
      d="M5.34664 8.98014C5.30512 8.90822 5.3115 8.81945 5.3662 8.75697C5.80212 8.25904 7.78595 6.13901 9.93664 6.17082C11.9511 6.20061 13.8192 4.34247 14.406 3.69782C14.5002 3.59427 14.6706 3.61082 14.7406 3.73208L18.281 9.86432C18.3196 9.93102 18.3171 10.0125 18.2707 10.074C17.9024 10.5622 16.1729 12.6633 13.6851 12.6633C11.4026 12.6633 9.75838 14.4319 9.2181 15.1009C9.12564 15.2154 8.941 15.2058 8.86742 15.0783L5.34664 8.98014Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />,
    <path
      key="3"
      d="M16.4697 6.72722C16.4697 6.72722 14.6793 9.62452 11.7944 9.42651C10.8225 9.35981 9.97479 9.64442 9.27794 10.0512"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />,
    <path
      key="4"
      d="M3.75 20H20.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />,
  ],
});

export const FileExportIcon = createIcon({
  displayName: "Country Risk",
  viewBox: "0 0 26 26",
  path: [
    <svg
      key="1"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
      />
    </svg>,
  ],
});

export const SupportIcon = createIcon({
  displayName: "Support",
  path: [
    <svg
      key="1"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="size-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
      />
    </svg>,
  ],
});
