import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
import colors from "./colors";

export const theme = extendTheme({
  config: {
    initialColorMode: "dark",
  },
  styles: {
    global: (props) => {
      const datePickerClasses = [
        "in-range",
        "in-selecting-range",
        "selected",
        "keyboard-selected",
      ]
        .map(
          (prop) =>
            `.react-datepicker__day--${prop}, .react-datepicker__day--${prop}:hover`
        )
        .join(",");

      return {
        body: {
          fontFamily: "Carnas",
          background: mode(colors.bgLight, "#171719")(props),
          color: mode("black", "white")(props),
          strokeWidth: "0px",
          fontSize: "16px",
        },
        code: {
          fontFamily: "SFMono-Regular,Menlo,Monaco,Consolas,monospace",
          padding: ".2em .4em",
          margin: "0",
          fontSize: "85%",
          backgroundColor: "rgba(110,118,129,0.4)",
          borderRadius: "6px",
        },
        ".editor-container": {
          borderRadius: "8px",
          overflow: "hidden",
        },
        ".react-datepicker": {
          border: "none",
          backgroundColor: colors.bgLight,
          borderRadius: "10px",
        },
        ".react-datepicker__header": {
          backgroundColor: colors.bgLight,
          borderBottom: "none",
          borderRadius: "10px !important",
        },
        [datePickerClasses]: {
          backgroundColor: colors.yellow,
          color: "black",
        },
      };
    },
  },

  // github.com/chakra-ui/chakra-ui/blob/master/packages/theme/src/foundations/colors.ts
  colors: {
    success: "#06E66D",
    warning: "#EB930F",
    error: "#EA1145",

    brand: {
      50: "#fffcea",
      200: "#ffe34d",
      300: "#ffdf33",
      400: "#ffdb1a",
      500: "#ffd700",
      600: "#e6c200",
      700: "#ccac00",
      800: "#b39700",
    },

    light: {
      100: "#F5F8FAff",
      200: "#EEF1F3ff",
      300: "#E6E9ECff",
      400: "#DFE2E4ff",
      500: "#D7DBDDff",
      600: "#D0D3D6ff",
      700: "#C8CCCFff",
      800: "#C1C4C7ff",
      900: "#B9BDC0ff",
    },

    dark: {
      100: "#252428ff",
      200: "#313033ff",
      300: "#3D3C3Fff",
      400: "#49484Aff",
      500: "#555456ff",
      600: "#626161ff",
      700: "#6E6D6Cff",
      800: "#7A7978ff",
      900: "#868583ff",
    },
  },

  // github.com/chakra-ui/chakra-ui/tree/master/packages/theme/src/components
  components: {
    Button: {
      defaultProps: {
        colorScheme: "brand",
        color: "black",
      },
      variants: {
        solid: {
          color: "black",
        },
      },
      baseStyle: {
        borderRadius: "10px",
      },
    },
    Checkbox: {
      defaultProps: {
        colorScheme: "brand",
      },
    },
    Link: {
      baseStyle: {
        color: "brand.800",
        fontWeight: "medium",
      },
    },
    Radio: {
      defaultProps: {
        colorScheme: "brand",
      },
    },
    Switch: {
      defaultProps: {
        colorScheme: "brand",
      },
    },
    Tabs: {
      defaultProps: {
        variant: "unstyled",
      },
    },
    Badge: {
      baseStyle: {
        borderRadius: "5px",
        padding: "4px 8px",
      },
    },
    Alert: {
      baseStyle: {
        container: {
          borderRadius: "8px",
          fontWeight: 500,
        },
      },
    },
    Slider: {
      defaultProps: {
        colorScheme: "brand",
      },
    },
    Input: {
      variants: {
        filled: (props) => ({
          _placeholder: {
            color: "black",
          },
          field: {
            background: mode("light.400", "dark.400")(props),
            color: mode("black", "white")(props),
            _focus: {
              background: mode("light.500", "dark.500")(props),
            },
            _hover: {
              background: mode("light.500", "dark.500")(props),
            },
          },
        }),
      },
    },
  },
});
