import React from "react";
import styled from "@emotion/styled";

import colors from "../../components/theme/colors";

import { Badge, Box, Text, useColorModeValue } from "@chakra-ui/react";
import { formatDate } from "../../globals/utils";

export interface CommentProps {
  content: string;
  createdAt: string;
  email: string;
  moderator: boolean;
  name: string;
}

const Content = styled(Box)`
  font-size: 15px;
  table {
    margin-top: 1.25rem;

    &:not(:last-child) {
      margin-bottom: 1.25rem;
    }

    th,
    td {
      text-align: center;
      padding: 0.75rem 1.5rem;
      border: 0.5px solid rgba(255, 255, 255, 0.35);
    }
  }
  .signature {
    margin-top: 0.5rem;
    line-height: 30px;
    hr {
      border: none;
    }
    a {
      color: #ffd700;
    }
  }
`;

const Comment = ({
  content,
  createdAt,
  email,
  moderator,
  name,
}: CommentProps): JSX.Element => {
  const cardBg = useColorModeValue(
    colors.cardBackgroundLight,
    colors.cardBackgroundDark
  );

  return (
    <Box bg={cardBg} borderRadius="lg" p="1.25rem">
      <Text
        display="inline-flex"
        align="center"
        mb="1rem"
        maxW="100%"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        <Text fontSize="md" fontWeight="semibold">
          {name}
        </Text>
        <Text fontSize="sm" ml="0.4rem" opacity="0.8">
          {email}
        </Text>
        {moderator && (
          <Badge
            color={useColorModeValue(colors.yellow, colors.yellow500)}
            fontSize={10}
            ml="0.4rem"
          >
            Mod
          </Badge>
        )}
        <Text fontSize="sm" marginInline="0.5rem" opacity="0.8">
          &middot;
        </Text>
        <Text fontSize="sm" opacity="0.8">
          {formatDate(createdAt, "MMMM DD, YYYY hh:mm A z")}
        </Text>
      </Text>
      <Content
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    </Box>
  );
};

export default Comment;
