import React, { useState, ChangeEvent } from "react";
import styled from "styled-components";

import Comment from "./comment";
import colors from "../../components/theme/colors";
import Header from "../../components/layout/Header";

import {
  Grid,
  GridItem,
  Text,
  Flex,
  Badge,
  Stack,
  useToast,
  Spinner,
  ThemingProps,
  Button,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";

import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { AxiosError } from "axios";

import {
  createSupportTicketComment,
  getSupportTicket,
  getSupportTicketComments,
} from "../../api/api";

import { SupportTicketComment } from "../../api/types";
import { formatDate } from "../../globals/utils";
import { Scrollbox } from "../../components/general/ScrollBox";
import { TextArea } from "../../components/general/TextArea";

const STATUS_VARIANTS: Record<string, ThemingProps["colorScheme"]> = {
  new: "red",
  pending: "red",
  open: "blue",
  solved: "green",
  closed: "green",
  hold: "gray",
};

const Content = styled(Scrollbox)`
  position: relative;
  height: 100%;
  max-width: 80%;
  overflow-y: scroll;
  padding-bottom: 1.5rem;
  padding-right: 1.5rem;
`;

const SupportTicket = (): JSX.Element => {
  const toast = useToast();
  const { ticketID } = useParams();

  const [textbox, setTextbox] = useState("");
  const [hasMore, setHasMore] = useState(false);
  const [pageAfter, setPageAfter] = useState("");
  const [comments, setComments] = useState<SupportTicketComment[]>([]);

  const linkColor = useColorModeValue(colors.yellow, colors.yellow500);

  const { data: ticket } = useQuery(
    ["support-tickets", ticketID],
    () => getSupportTicket(ticketID),
    {
      onError: (err: Error) => {
        toast({
          title: "An error occurred fetching the support ticket",
          description: err.message,
          status: "error",
        });
      },
    }
  );

  const { isLoading, refetch } = useQuery(
    ["support-tickets", ticketID, "comments"],
    () => getSupportTicketComments(ticketID, pageAfter),
    {
      onSuccess: ({ comments, has_more, after_cursor }) => {
        setComments((prev) => [...prev, ...comments]);
        setHasMore(has_more);
        setPageAfter(after_cursor);
      },
      onError: (err: Error) => {
        toast({
          title: "An error occurred fetching the support ticket",
          description: err.message,
          status: "error",
        });
      },
    }
  );

  const mutation = useMutation<void, AxiosError, string>(
    (body: string) => createSupportTicketComment(ticketID, body),
    {
      onSuccess: async () => {
        await refetch();
        setTextbox("");
      },
    }
  );

  const loadMoreComments = () => {
    refetch();
  };

  const submitComment = async () => {
    if (textbox !== "") {
      mutation.mutate(textbox);
    }
  };

  const isTextboxDisabled =
    ticket?.status === "solved" ||
    ticket?.status === "closed" ||
    mutation.isLoading === true;

  return (
    <Grid height="100%" templateRows="minmax(200px, auto) 1fr 100px">
      <GridItem>
        <Header title={`Support > Tickets > #${ticketID}`} />
        <Flex align="center">
          <Badge
            colorScheme={STATUS_VARIANTS[ticket?.status ?? "Fetching"]}
            fontSize="x-small"
            mr="0.6rem"
          >
            {ticket?.status ?? "Fetching"}
          </Badge>
          <Text
            fontSize="2xl"
            fontWeight="semibold"
            maxWidth="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {ticket?.subject ?? "Fetching Ticket Subject..."}
          </Text>
        </Flex>
        <Flex gap="2rem" margin="1rem 0" maxWidth="80%">
          <Box minWidth="65px">
            <Text color="lightgray" fontSize="sm">
              Ticket #
            </Text>
            <Text color={linkColor} fontSize="lg" fontWeight="500">
              {ticketID}
            </Text>
          </Box>
          <Box minWidth="65px">
            <Text color="lightgray" fontSize="sm">
              Requested At
            </Text>
            <Text color={linkColor} fontSize="lg" fontWeight="500">
              {formatDate(ticket?.requested_at, "MMMM DD, YYYY hh:mm A z")}
            </Text>
          </Box>
          <Box minWidth="65px">
            <Text color="lightgray" fontSize="sm">
              Last Updated At
            </Text>
            <Text color={linkColor} fontSize="lg" fontWeight="500">
              {formatDate(ticket?.last_updated_at, "MMMM DD, YYYY hh:mm A z")}
            </Text>
          </Box>
        </Flex>
      </GridItem>
      <Content>
        {isLoading ? (
          <Flex align="center" justify="center" height="100%">
            <Spinner size="xl" />
          </Flex>
        ) : (
          <Stack gap="0.75rem">
            {comments.map((comment, i) => (
              <Comment
                key={i}
                content={comment.content}
                createdAt={comment.created_at}
                email={comment.author.email}
                moderator={comment.author.moderator}
                name={comment.author.name}
              />
            ))}
            {hasMore && (
              <Button alignSelf="center" onClick={loadMoreComments}>
                Load More
              </Button>
            )}
          </Stack>
        )}
      </Content>
      <Flex align="center" gap="1rem" maxWidth="80%">
        <TextArea
          disabled={isTextboxDisabled}
          onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
            setTextbox(event.target.value)
          }
          placeholder={`Reply to #${ticketID}`}
          value={textbox}
          padding="1rem"
          resize="none"
        />
        <Button
          onClick={submitComment}
          isDisabled={isTextboxDisabled || textbox.length === 0}
          isLoading={mutation.isLoading}
        >
          Submit
        </Button>
      </Flex>
    </Grid>
  );
};

export default SupportTicket;
